//PROJECT STYLES
@import "buttons";
@import "forms";
@import "type";
@import "nav";
@import "buttons";
@import "images";
@import "account";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
.full-width {
  width: 100%;
}


.scroll-hover {
  padding-bottom: $spacer;
  /* width */
  &::-webkit-scrollbar {
    height: 12px;
    margin: 12px;
    width: 12px;
    opacity: 0;
  }

  /* Track */
  &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
    visibility: hidden;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(255,255,255,.15);
  }
  &:hover {
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
      visibility: visible;
      opacity: 1;

    }
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
    border: 3px solid transparent;
    background-color: rgba(255,255,255,.25);
    border-radius: $border-radius;
    &:hover {
      background-color: rgba(255,255,255,.25);
    }
  }
}
.cursor-default {
  cursor: default;
}
