

.h1,
h1 {
  line-height: $h1-line-height;
}
.h2,
h2 {
  line-height: $h2-line-height;
}
.h3,
h3 {
  line-height: $h3-line-height;
}
.h4,
h4 {
  line-height: $h4-line-height;
}
.h5,
h5 {
  line-height: $h5-line-height;
}
.h6,
h6 {
  line-height: $h6-line-height;
}
.feature-icon {
  line-height: $h6-line-height;
  font-size: $h6-font-size;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6{

  font-weight: $font-weight-bold;
  letter-spacing: -0.02em;
}

.text-bold {
  font-weight: $font-weight-bold;
}

.text-semibold {
  font-weight: $font-weight-semibold;
}

.text-regular {
  font-weight: $font-weight-regular;
}

.text-xs{
  font-size: $font-size-xs;
  line-height: $line-height-base;
}

.text-small {
  font-size: $font-size-small;
  line-height: $line-height-small;
}

.text-large{
  font-size: $font-size-large;
  line-height: $line-height-large;
}

.rounded-sm{
  border-radius: 10px;
}

.rounded-lg {
  border-radius: 32px;
}

.rounded-xl{
  border-radius: 48px;
}

.font-brand {
  font-family: $brand-font-family;
}


.text-black {
 h1,h2,h3,h4,h5,h6 {
   color: $black;
 }
}

.legal {
  padding-top: 200px;
  font-size: 20px;
  letter-spacing: -0.3px;
  font-weight: 400;
  h1 {

    font-size: 2.5rem;
    line-height: 1.2;
    font-weight: 600;
    margin-bottom: 32px;
  }
  h2 {
    margin-bottom: 16px;
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 600;
  }
}
