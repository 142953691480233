@import "variables";
@import "3rdParty/index";
@import "flexbox/index";
@import "project/index";
@import "project/colour";

.cursor-pointer {
  cursor: pointer;
}

.position-relative {
  position: relative;
}
.modal-content {
  background: $body-bg;
}

$footer-height: 85px;

footer {
  height: $footer-height;
  line-height: $footer-height;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(21, 23, 24, 0.75);
  //background-color: red;
  a {
    color: white;
    font-size: 18px;
    font-weight: 400;
    margin-right: 32px;
    text-decoration: none !important;
  }
}
.main {
  padding-bottom: 85px;
  min-height: calc(100vh - 155px);
}

.screenshots {
  img {
    height: calc(100vh - 300px);
    min-height: 400px;
  }
}
