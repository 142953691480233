.btn-primary {
  color: $btn-primary-color;
}

.btn-secondary {
  padding:0px 0px !important;
  border-radius: 48px;
  border: solid 3px transparent;
  background-image: linear-gradient($black, $black),
    linear-gradient(112.4deg, #ff6392 29.63%, #89e6e3 76.7%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.btn-secondary:hover {
  border-color: white;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 48px;
  height: $btn-height;
  padding:7px 26px;
}

.btn-sm {
  height: 32px;
  line-height: 18px;
  font-size: 13px;
}

.btn-link{
  color:$textDark;
  font-size: 13px;
  text-decoration: none;
}
.btn-link:hover{
  color:$textDark;
  text-decoration: underline;
}

.download-app-btn{
min-height: 78px;
padding: 18px 30px;
color: $lightgrey;
border: none;
border-radius: 12px;
min-width: 200px;


}


.btn-close {
  display: none;
}

.modal-header,.modal-footer {
  border: none;
}
