.nav-item a {
  text-decoration: none !important;
}
.aside {
  height: calc(100vh - 60px);
}

.navbar {
  z-index: 1;
}
