
.invalid input {
  border-color: $danger;
}
label {
  font-weight: $font-weight-semibold;
}

.form-control {
  background-clip: unset;
}

.form-control:disabled{
  background-color: $blackLight;
}

input:-webkit-autofill {
  @extend .form-control;

  background-clip: content-box;
  -webkit-box-shadow:0 0 0 50px $inputBackground inset; /* Change the color to your own background color */
  -webkit-text-fill-color: $white;

}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;/*your box-shadow*/
  -webkit-text-fill-color: #333;
}
.input-icon {
  top:10px;
  line-height: 32px;
  font-size: 26px;
  position: absolute;
  left: 16px;
  color: $text-muted;
  &--right {
    right: 16px;
    left:auto;
  }
}


.form-control--with-icon {
  text-indent: 40px;
}

.form-control--with-icon-right {
  padding-right: 56px;
}

.panel {
  cursor: pointer;
  border-radius: $border-radius-sm;
  padding: $spacer;
  background-color: $blackLight;
  &--active {
    background-color: $turquoise;
  }
}

textarea {
  border-radius: 10px !important;
}
.text-right {
  text-align: right;
}

.toast-container {
  position: fixed;
  z-index: 100000000;
  .toast {
    font-weight: $font-weight-semibold;
    min-width: 300px;
  }
}

.alert-warning {
  font-size: 13px;
  svg {
    width: 16px;
    height: 16px;
  }
  border: 1px solid $input-border-color;
  border-radius: 8px;
  padding: $spacer;
}
