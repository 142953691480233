.background-hero {
  position: absolute;
  top: 0;
  right: 0;
}

@include media-breakpoint-down(lg) {
  .background-hero {
    width: 360px;
    top: -155px;
  }
}

.navbar-avatar {
  height: 25px;
  width: 25px;
  border-radius: 9px;
}
