@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";

// Customize some Bootstrap defaults
//$body-color: #333;
$body-bg: #fefefe;
//$border-radius: .4rem;

// Override some or all of Bootstrap's individual color variables
$black: #151718;
$blackLight: #282A2C;
$blackLighter:#3C3E40;

$tooltip-bg:$blackLight;
$tooltip-text:$body-color;
$blackLightPressed: #111213;
$blueDark: #4EA5D9;
$bodyBackground: #151718;
$danger: #DE3C4B;
$defaultCategoryColour: #97DFFC;
$drawerBackgroundColor: #151718;
$green: #56E39F;
$iOSBlue: #0A84FF;
$inputBackground: #272A2C;
$inputBorder: #272A2C;
$lightBlue: #97DFFC;
$lightgrey: #DDDDDD;
$link: #89E6E3;
$linkPressed: #92fdf8;
$pink: #FF6392;
$primary: #FFF48D;
$primaryOutline: #FFF48D;
$primaryPressed: #eadf81;
$red: #DE3C4B;
$redLight: #F3727E;
$secondary: #141718;
$secondaryPressed: #101313;
$success: #1AC0C6;
$tertiary: #282A2C;
$tertiaryPressed: #1e2021;
$text: #fff;
$textDark: #122C34;
$textHighlight: #89E6E3;
$textMuted: #848C8E;
$textMutedLighter: #A9AAAB;
$turquoise: #89E6E3;
$white: #fff;
$yellow: #FFF48D;
$dark-grey:#787878;
$danger: #d63384;

// This adds className="bg-grey1","text-grey1" etc
$theme-colors: (
        black: $black,
        blackLight: $blackLight,
        blackLighter: $blackLighter,
        primary: $primary,
        secondary: $secondary,
        tertiary: $tertiary,
        danger: $danger,
        pink: $pink,
        turquoise: $turquoise,
        lightBlue: $lightBlue,
        darkGrey: $dark-grey,
  // add any additional color below
);

$border-radius:48px;
$border-radius-sm:36px;

//Buttons
$btn-border-radius: 48px;
$btn-height: 55px;
$btn-padding-x: $spacer*2;
$btn-padding-x-lg: $spacer*3;
$btn-font-weight: $font-weight-semibold;
$btn-primary-hover-background: $primaryPressed;

$btn-secondary-color: white;
$btn-secondary-hover-color: white;
$btn-secondary-hover-background: $secondaryPressed;

$btn-shadow: 0 10px 20px transparentize($secondary, 0.75);
$btn-shadow-primary: 0 10px 20px transparentize($primary, 0.66);

////Typography
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$font-family-base: Graphik, sans-serif; //All text
$headings-font-family: Graphik, sans-serif; //h1-h6
$display-font-family: Graphik, sans-serif; //anything with the class name display
$btn-font-family: Ilisarniq, sans-serif; //anything with the class name display
$input-font-family: Ilisarniq, sans-serif; //anything with the class name display
$brand-font-family: Antonio, sans-serif; //anything with the class name display

$text-muted: $textMuted;
$body-color: $white;
$body-bg: $black;
$headings-color: $white;
$link-color: $link;
$link-hover-color: $link;



$headings-margin-bottom: $spacer;

// All the font sizes
$font-size-xs: .813rem;

$font-size-small:1rem;
$line-height-small:1.25rem;

$font-size-base: 1.125rem; // default text
$line-height-base: 1.5;

$font-size-large:1.25rem;
$line-height-large:1.62rem;

$h1-font-size:4.55rem;
$h1-line-height:5.625rem;

$h2-font-size:3.75rem;
$h2-line-height:4.5rem;

$h3-font-size:3rem;
$h3-line-height:3.75rem;

$h4-font-size:2.25rem;
$h4-line-height:2.75rem;

$h5-font-size:1.875rem;
$h5-line-height:2.375rem;

$h6-font-size:1.333rem;
$h6-line-height:2rem;

//Scaffolding
$breakpoints: (
        mobile-small: 320px,
        mobile: 375px,
        handheld-device: 576px,
        tablet: 768px,
        tablet-large: 992px,
        desktop: 1200px
);


  // Start of Font information
@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: $font-weight-regular;
  src: url(/fonts/GraphikRegular.otf) format("opentype");
  font-display: swap;
}

@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: $font-weight-semibold;
  src: url(/fonts/GraphikSemibold.otf) format("opentype");
  font-display: swap;
}

@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: $font-weight-bold;
  src: url(/fonts/GraphikBold.otf) format("opentype");
  font-display: swap;
}
@font-face {
  font-family: Ilisarniq;
  font-style: normal;
  font-weight: $font-weight-regular;
  src: url(/fonts/IlisarniqRegular.otf) format("opentype");
  font-display: swap;
}

@font-face {
  font-family: Ilisarniq;
  font-style: normal;
  font-weight: $font-weight-semibold;
  src: url(/fonts/IlisarniqBold.otf) format("opentype");
  font-display: swap;
}

@font-face{
  font-family: Antonio;
  font-style: normal;
  font-weight: $font-weight-regular;
  src: url(/fonts/Antonio-Regular.ttf) format("truetype");
  font-display: swap;
}

// button
$btn-border-radius: $border-radius;
$btn-font-family: $btn-font-family;
$btn-font-weight: $font-weight-semibold;
$btn-line-height: 44px;
$btn-padding-y: 0;
$btn-primary-color: $textDark;
// input
$input-bg: $inputBackground;
$input-border-color: $inputBackground;
$input-border-width:3px;
$input-border-radius: $border-radius;
$input-color: $white;
$input-focus-bg: $inputBackground;
$input-focus-border-color: $turquoise;
$input-focus-box-shadow: none;
$input-focus-color: $white;
$input-font-family: $btn-font-family;
$input-font-weight: $font-weight-regular;
$input-line-height: 44px;
$input-padding-y: 0;

$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        '2-half': $spacer * .75,
        3: $spacer,
        '3-half': $spacer * 1.25,
        4: $spacer * 1.5,
        '4-half': $spacer * 1.75,
        5: $spacer * 2,
        '5-half': $spacer * 2.25,
        6: $spacer * 2.5,
        7: $spacer * 3,
        8: $spacer * 3.5,
        9: $spacer * 4,
        10: $spacer * 4.5,
        11: $spacer * 5,
        12: $spacer * 5.5,
        13: $spacer * 6,
        14: $spacer * 6.5,
        15: $spacer * 7,
        16: $spacer * 7.5,
        17: $spacer * 8,
        18: $spacer * 8.5,
        19: $spacer * 9,
        20: $spacer * 9.5,
        21: $spacer * 10,
        22: $spacer * 10.5,
        23: $spacer * 11,
        24: $spacer * 11.5,
        25: $spacer * 12,
        26: $spacer * 12.5,
        27: $spacer * 13,
        28: $spacer * 13.5,
        29: $spacer * 14,
        30: $spacer * 15,

);
